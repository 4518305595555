import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Code from '../components/code';
import Caption from '../components/Caption';
import LittleImg from '../components/LittleImg';
import Video from '../components/Video';
import Action from '../components/Action';
export const _frontmatter = {
  "title": "Practice CSS Like an Artist",
  "date": "2021-01-20",
  "promo": "grids",
  "description": "Learn the fine art of practice",
  "color": "#d6573e"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As a kid I became interested in oil painting. My dad is a professional artist and I wanted to be like him. One day I asked if he would teach me. The invaluable lesson he gave me shaped how I learned to code and how I became good at CSS.`}</p>
    <p>{`Our house was like one big art gallery — paintings, pottery & sculptures everywhere. Dad walked me over to the nearest Arlo Coles painting on the wall, and said "`}<em parentName="p">{`paint this`}</em>{`":`}</p>
    <p><img parentName="p" {...{
        "src": "/img/arlo-coles.jpg",
        "alt": "Arlo Coles painting"
      }}></img></p>
    <p><em parentName="p">{`"Okay...how?"`}</em>{` I asked. "`}<em parentName="p">{`You'll figure it out.`}</em>{`" he replied. When pressed for more help, he said "`}<em parentName="p">{`Start with broad strokes, get the overall composition right. Don't worry about all the details.`}</em>{`"`}</p>
    <p>{`I did my best to copy this awesome painting. The result wasn't very good, but I learned a lot. He had me try again and again. Here's one attempt that I actually kept:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/arlo-attempt.jpg",
        "alt": "Painting attempt"
      }}></img></p>
    <h2>{`Great artists steal`}</h2>
    <p>{`Trying to recreate great work is one of the most effective ways to improve any skill— not just art. I used this same exercise to learn web development. I'd find a website that I thought looked slick, and I'd try to recreate it myself.`}</p>
    <p>{`Sometimes I'd have to cheat and peek under the hood with the devtools. I'd see things in the code that made zero sense, and I'd have to go study them. I learned `}<em parentName="p">{`so`}</em>{` much doing this. It was way more effective than any of my University assignments. If you're at all like me, you `}<a parentName="p" {...{
        "href": "/post/deliberate-practice/"
      }}>{`learn best by doing`}</a>{`.`}</p>
    <p>{`Obviously it's not cool to rip off someone else's work and pass it off as your own, so only use this as a learning exercise.`}</p>
    <p>{`To this day I still use this exercise to continue refining my skills. I'm often inspired by the UIs in video games. The designs and interactions are exciting. Recreating them really stretches your coding skills. Plus it means you `}<em parentName="p">{`have`}</em>{` to play games... for research ;)`}</p>
    <p>{`Here you can watch me build the `}<a parentName="p" {...{
        "href": "/build/zelda/"
      }}>{`Zelda UI`}</a>{` and the `}<a parentName="p" {...{
        "href": "/build/overwatch-hero-picker/"
      }}>{`Overwatch UI`}</a>{`.`}</p>
    <h2>{`Broad strokes first`}</h2>
    <p>{`From a lifetime of teaching students, my dad knew to help me avoid the biggest pitfall: getting caught up in the details too soon. His `}<em parentName="p">{`broad strokes first`}</em>{` advice was incredibly helpful. But he didn't come up with the idea. Great artists have been using this principle for centuries.`}</p>
    <p>{`A classic example can be seen in `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Eug%C3%A8ne_Delacroix"
      }}>{`Eugène Delacroix`}</a>{`'s famous `}<em parentName="p">{`Study of Lions`}</em>{`. `}</p>
    <p><img parentName="p" {...{
        "src": "/img/study-of-lions.jpg",
        "alt": "Study of lions"
      }}></img></p>
    <p>{`With a few bold, sweeping strokes he defines the composition of each piece. He probably `}<em parentName="p">{`had`}</em>{` to work fast as he preferred to draw the lions while they fed. Delacroix was masterful at capturing the overall essence, leaving the fine details for later.`}</p>
    <p>{`So how do we apply this broad strokes principle to practicing CSS?`}</p>
    <h2>{`Layout`}</h2>
    <p>{`CSS has a ton of properties, but you could group them into just two categories: `}<em parentName="p">{`broad strokes`}</em>{` or `}<em parentName="p">{`fine details`}</em>{`.`}</p>
    <p>{`The primary broad stroke tools are Grid, Flexbox, and Position. All the rest are fine details: hover interactions, animations, padding, borders, clip-path etc.`}</p>
    <p>{`To use broad strokes, you build the site `}<strong parentName="p">{`outside-in`}</strong>{`. You focus first on the overall composition. CSS Grid is `}<em parentName="p">{`especially`}</em>{` good for this, as it lets you define an entire `}<a parentName="p" {...{
        "href": "/post/grid-for-responsive-layout/"
      }}>{`responsive layout`}</a>{` in a `}<a parentName="p" {...{
        "href": "/post/grid-beats-flexbox-full-page-layout/"
      }}>{`single stroke`}</a>{`.`}</p>
    <p>{`In the book `}<em parentName="p">{`Keys to Drawing`}</em>{`, Bert Dodson clarifies the broad strokes principle. He's talking about pencil strokes but might as well be talking about divs:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Rule #1. Draw the large shapes first, then the smaller shapes... it's easier to work from the general to the specific than the other way around. Start with the largest shape you see. Forget everything else and draw that shape... Now you have something you can build on, restate, compare with surrounding shapes, subdivide into smaller shapes, etc.`}</p>
    </blockquote>
    <p>{`Dodson also teaches a nifty little trick: you `}<strong parentName="p">{`squint`}</strong>{` and look at your subject. By squinting you can no longer see a lot of the small details, but just the big broad shapes. Try it next time you go to build a layout!`}</p>
    <p>{`I encourage every dev to master these broad strokes tools of layout. I've made this as easy and fun as possible with my `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` and `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` mastery games. Once you have those broad stroke tools down, practice CSS by recreating the awesome work you admire. You'll `}<a parentName="p" {...{
        "href": "https://mastery.games/post/power-of-mastery/"
      }}>{`become amazing`}</a>{` at CSS before you know it!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      